/*********************************************************************************

	Template Name: Trydo React Degital Agency Template
	Note: This is style css.

**********************************************************************************/

/**************************************************************
	
	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ extend
	|	|___ animations
	|	|___ shortcode
	|	|___ spacing
	|
	|
	|___Header Styles
	|	|___ header
	|	|___ mainmenu
	|
	|
	|___Inner Styles
	|	|___ breadcrump 
	|	|___ section-title 
	|	|___ button
	|	|___ pagination
	|	|___ socialicon
	|	|___ footer
	|
	|___Elements Styles
	|	|___ portfolio 
	|	|___ service 
	|	|___ slider 
	|	|___ counterup 
	|	|___ testimonial 
	|	|___ brand 
	|	|___ team 
	|	|___ callto-action 
	|	|___ tab 
	|	|___ accordion 
	|	|___ list 
	|	|___ contact 
	|
	|___BLog Styles
	|	|___ blog 
	|	|___ blog-details 
	|
	|___Template Styles
	|	|___ about 
	|	|___ portfolio-details
	|	|___ service-details
	|	|___ preview
	|
	|
	|___ END STYLESHEET INDEXING

***************************************************************/


/**************************************
    Default Styles
***************************************/
@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';



/**************************************
    Header Styles
***************************************/

@import 'header/header';
@import 'header/mainmenu';


/**************************************
    Inner Styles
***************************************/

@import 'common/breadcrump';
@import 'common/section-title';
@import 'common/button';
@import 'common/pagination';
@import 'common/socialicon';
@import 'common/footer';



/**************************************
    Elements Styles
***************************************/

@import 'elements/portfolio';
@import 'elements/service';
@import 'elements/slider';
@import 'elements/counterup';
@import 'elements/testimonial';
@import 'elements/brand';
@import 'elements/team';
@import 'elements/callto-action';
@import 'elements/tab';
@import 'elements/accordion';
@import 'elements/list';
@import 'elements/contact';
@import 'elements/progress';
@import 'elements/column';
@import 'elements/404';
@import 'elements/pricing';



/**************************************
    Blog Styles
***************************************/

@import 'blog/blog';
@import 'blog/blog-details';



/**************************************
    Template Styles
***************************************/

@import 'template/about';
@import 'template/portfolio-details';
@import 'template/service-details';
@import 'template/dark-version';
@import 'template/preview';


.product-list .product-box {
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.product-list .product-box .item {
    background: transparent;
    border-radius: 10px;
    box-shadow: 0 0 5px rgb(0 0 0 / 25%);
    min-height: 250px;
}
.product-list .product-box .img-box img{
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.product-list .product-box .item .cont {
    padding: 10px;
}
.product-list .product-box .item .product-title {
    color: #c6c612;
    font-size: 16px;
    font-weight: 600;
}
.product-list .product-box .item .product-category {
    color: #2e2e2e;
    font-size: 14px;
}
.product-list .product-box .item .product-detail {
    color: #2e2e2e;
    display: flex;
    flex-direction: row;
    font-size: 13px;
    font-weight: 500;
    justify-content: space-between;
}

.bg_image_contact {
	background-image: url('/assets/images/bg/contact.jpg');
}
.bg_image_about {
	background-image: url('/assets/images/bg/about.jpg');
}
.bg_image_blog {
	background-image: url('/assets/images/bg/blog.jpg');
}
.bg_image_product {
	background-image: url('/assets/images/bg/product.png');
}
.bg_image_exports {
	background-image: url('/assets/images/bg/exports.jpg');
}
.bg_image_catalogues {
	background-image: url('/assets/images/bg/catalogues.png');
}
.breadcrumb-area .title, .rn-page-title-area .title, .title.theme-gradient {
    background: none !important;
	-webkit-text-fill-color: #dbdb20 !important;
}

.mainmenunav ul.mainmenu>li>ul.submenu li a:hover {
	color: #000 !important;
}
.mainmenunav ul.mainmenu>li>ul.submenu li:hover>a {
	background-color: #dbdb20 !important;
}
.footer-left .inner::after {
	content: "";
}
.footer-left {
	background: linear-gradient(145deg, #dbdb20 0%, #dbdb20 100%)
}